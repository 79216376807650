// import { stringToDateWithTime, dateToStringWithTime } from '@/utils/dateUtils';
import { removeComma } from "@/utils/stringUtils";
import PaymentCalculateModel from "./PaymentCalculateModel";

class PaymentInfoModel {
  constructor() {
    this.certificateId = null; // 증빙번호
    this.currency = "KRW"; // 통화
    this.expenditureDate = ""; // 지급예정일
    this.modReason = ""; // 수정사유
    this.paymentCalculateList = []; // 정산내역
    // this.paymentCalculateList.push(new PaymentCalculateModel());

    this.paymentCid = ""; // 결제내역번호
    this.paymentType = ""; // 결제수단
    this.supplyPrice = ""; // 공급가액
    this.taxAmount = ""; // 세액(공급사액 10%)
    this.totalAmount = ""; // 총액(공급가액 + 세액)
    this.totalInspectPrice = ""; // 검수금액 합

    this.paymentId = ""; // 결제내역번호
    this.modifiedFlag = false;
  }

  setData(obj) {
    if (obj.certificateId) this.certificateId = obj.certificateId;
    if (obj.currency) this.currency = obj.currency;
    if (obj.expenditureDate) this.expenditureDate = obj.expenditureDate;
    if (obj.modReason) this.modReason = obj.modReason;
    if (obj.paymentCid) this.paymentCid = obj.paymentCid;
    if (obj.paymentId) this.paymentId = obj.paymentId;
    if (obj.paymentType) this.paymentType = obj.paymentType;
    if (obj.supplyPrice) this.supplyPrice = obj.supplyPrice;
    if (obj.taxAmount) this.taxAmount = obj.taxAmount;
    if (obj.totalAmount) this.totalAmount = obj.totalAmount;
    if (obj.totalInspectPrice) this.totalInspectPrice = obj.totalInspectPrice;

    if (obj.paymentCalculateList) {
      this.paymentCalculateList = obj.paymentCalculateList.map((item) => {
        const itemModel = new PaymentCalculateModel();
        itemModel.setData(item);
        return itemModel;
      });
    }
  }

  getData() {
    let obj = {
      certificateId: this.certificateId,
      currency: this.currency,
      expenditureDate: this.expenditureDate,
      modReason: this.modReason,
      paymentCid: this.paymentCid,
      paymentId: this.paymentId,
      paymentType: this.paymentType,
      supplyPrice: removeComma(this.supplyPrice),
      taxAmount: this.taxAmount,
      totalAmount: this.totalAmount,
      totalInspectPrice: this.totalInspectPrice,
    };
    obj.paymentCalculateList = this.paymentCalculateList.map((item) => item.getData());

    return obj;
  }

  getAlertMessage(proofType = "") {
    if (
      this.modifiedFlag == true &&
      (proofType === "AT" || proofType === "AB" || proofType === "CP")
    ) {
      // if(this.modReason == '') return '지급예정일 수정사유를 입력해주세요.';
    }
  }
}

export default PaymentInfoModel;
