import { stringToDateWithTime, dateToStringWithTime } from "@/utils/dateUtils";

class PaymentCalculateModel {
  constructor() {
    this.calculateId = 0;
    this.draftCid = ""; // 기안 일련번호
    this.inspectCompleteDate = null; // 검수 날짜
    this.inspectPrice = ""; // 검수 가격
    this.paymentCid = ""; // 결제내역번호
  }

  setData(obj) {
    if (obj.calculateId) this.calculateId = obj.calculateId;
    if (obj.draftCid) this.draftCid = obj.draftCid;
    if (obj.inspectCompleteDate)
      this.inspectCompleteDate = stringToDateWithTime(obj.inspectCompleteDate);
    if (obj.inspectPrice) this.inspectPrice = obj.inspectPrice;
    if (obj.paymentCid) this.paymentCid = obj.paymentCid;
  }

  getData() {
    let obj = {
      calculateId: this.calculateId,
      draftCid: this.draftCid,
      inspectCompleteDate: this.inspectCompleteDate,
      inspectPrice: this.inspectPrice,
      paymentCid: this.paymentCid,
    };

    return obj;
  }
}

export default PaymentCalculateModel;
